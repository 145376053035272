import tupeloApi from '../helpers';
import Scenario from './Scenario';

const getScenariosForExperiment = async (experimentId: string): Promise<Scenario[]> => {
  const result = await tupeloApi.get(`/experiments/${experimentId}/scenarios`);
  return result.data as Scenario[];
};

const getVideoScenariosForExperiment = async (experimentId: string): Promise<Scenario[]> => {
  const result = await tupeloApi.get(`/experiments/${experimentId}/video-scenarios`);
  return result.data as Scenario[];
};

const updateScenario = async (scenario: Scenario): Promise<Scenario> => {
  const result = await tupeloApi.put(`/scenarios/${scenario.id}`, scenario);
  return result.data as Scenario;
};

const addScenario = async (scenario: Scenario): Promise<Scenario> => {
  const result = await tupeloApi.post('/scenarios', scenario);
  return result.data as Scenario;
};

export default {
  getScenariosForExperiment,
  getVideoScenariosForExperiment,
  updateScenario,
  addScenario,
};
