const lookCountRowWrapper = {
  display: 'flex',
  flex: '0 1',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  // borderBottom: '1px solid rgb(40, 40, 40)',
};

const lookCountRowSvgWrapper = {
  display: 'flex',
  flex: '0 1',
};

const lookCountRowLabel = {
  display: 'flex',
  flex: '0 1',
  fontSize: '1.5rem',
  fontWeight: '400',
};

export {
  lookCountRowWrapper,
  lookCountRowLabel,
  lookCountRowSvgWrapper,
};
