import React from 'react';
import {Box} from '@mui/material';
import ActionLog from '../../api/ActionLogs/ActionLog';
import {lookAnalysisRow, lookAnalysisWrapper} from './LookAnalysis.Styles';
import LookCounts from '../LookCounts/LookCounts';
import FirstLooks from '../FirstLooks/FirstLooks';
import LookDuration from '../LookDuration/LookDuration.d3';
import SentimentLog from '../../api/SentimentLogs/SentimentLog';
import ReportScenarioSummary from '../ReportScenarioSummary/ReportScenarioSummary';

type LookAnalysisProps = {
  lookLogs: ActionLog[];
  lookNames: string[];
  startTime: Date;
  duration: number;
  sentimentLogs: SentimentLog[];
  selectedSentiment?: SentimentLog;

};

function LookAnalysis(props: LookAnalysisProps) {
  return (
    <Box sx={lookAnalysisWrapper}>
      <Box sx={lookAnalysisRow}>
        <FirstLooks
          lookLogs={props.lookLogs}
          lookNames={props.lookNames}
          startTime={props.startTime}
          duration={props.duration}
        />
        <LookCounts
          lookLogs={props.lookLogs}
          lookNames={props.lookNames}
        />
      </Box>
      <Box sx={lookAnalysisRow}>
        <LookDuration
          lookLogs={props.lookLogs}
          lookNames={props.lookNames}
        />
        <ReportScenarioSummary
          startTime={props.startTime}
          sentimentLogs={props.sentimentLogs}
          selectedSentiment={props.selectedSentiment}
        />
      </Box>
    </Box>
  );
}

export default LookAnalysis;
