import {panel} from '../../styles/styles';

const sentimentSummaryWrapper = {
  ...panel,
  display: 'flex',
  flex: '1 0',
  flexDirection: 'column',
  alignItems: 'stretch',
  padding: '1rem',
};

const sentimentSummaryRow = {
  display: 'flex',
  flex: '1 0',
  flexDirection: 'row',
  padding: '.25rem',
  alignItems: 'stretch',
  justifyContent: 'space-between',
  '& button': {
    display: 'flex',
  },
  '& h2': {
    display: 'flex',
    textAlign: 'center',
    fontSize: '2rem',
    fontWeight: 400,
    color: '#436833',
    flex: '1 0 0',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    justifyContent: 'center',
  },
  '& h4': {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.25rem',
    fontWeight: 400,
    color: 'text.primary',
    flex: '1 0 0',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    justifyContent: 'center',
  },
};

const countItem = {
  display: 'flex',
  flex: '0 1',
  flexDirection: 'column',
  alignItems: 'stretch',
  textAlign: 'center',
  '& h3': {
    fontSize: '2.25rem',
    fontWeight: 400,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  '& h6': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
};

const sentimentCountsWrapper = {
  display: 'flex',
  flex: '0 1',
  flexDirection: 'column',
  // alignItems: 'stretch',
  minHeight: '4rem',
  textAlign: 'left',
};

const selectedLogWrapper = {
  display: 'flex',
  flex: '0 1',
  flexDirection: 'column',
  // alignItems: 'stretch',
  minHeight: '4rem',
  textAlign: 'left',
};

const sentimentLogRow = {
  display: 'flex',
  flex: '0 1',
  flexDirection: 'row',
  padding: '.25rem',
  alignSelf: 'stretch',
  justifyContent: 'space-between',
};

const sentimentSummaryContent = {
  display: 'flex',
  flex: '1 0',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'space-evenly',
};

export {
  sentimentSummaryWrapper,
  sentimentSummaryRow,
  countItem,
  sentimentCountsWrapper,
  selectedLogWrapper,
  sentimentLogRow,
  sentimentSummaryContent,
};
