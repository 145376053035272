import tupeloApi from '../helpers';
import ScenarioRun from './ScenarioRun';

const getScenarioRunsForTrial = async (trialId: string): Promise<ScenarioRun[]> => {
  const result = await tupeloApi.get(`/trials/${trialId}/scenario-runs`);
  return result.data as ScenarioRun[];
};

export default {
  getScenarioRunsForTrial,
};
