import React, {useRef, useEffect} from 'react';
import * as d3 from 'd3';
import {Box, Typography} from '@mui/material';
import {capitalize} from 'lodash';
import {lookCountRowLabel, lookCountRowSvgWrapper, lookCountRowWrapper} from './LookCountRow.Styles';
import LookStat from '../LookAnalysis/LookStat';

type LookAnalysisRowProps = {
  lookStat: LookStat;
  totalLogs: number;
  color: string;
}

function LookCountRow(props: LookAnalysisRowProps) {
  const d3Container = useRef<SVGSVGElement>(null);
  const dimensions = {
    height: 50,
    width: 50,
  };

  const lookColorOutFilter = d3.rgb(150, 150, 150).toString();

  const updateSvg = () => {
    if (d3Container.current) {
      const svg = d3.select(`#${d3Container?.current.id}`);

      svg.selectAll('.pie').remove();

      svg.append('text')
        .classed('pie', true)
        .attr('x', dimensions.width / 2)
        .attr('y', dimensions.height - 17)
        .style('text-anchor', 'middle')
        .style('font-size', 20)
        .style('fill', props.color)
        .text(props.lookStat.value);

      svg.append('path')
        .classed('pie', true)
        .attr('transform', 'translate(25,25)')
        .style('fill', lookColorOutFilter)
        .attr('d', d3.arc()({
          innerRadius: 15,
          outerRadius: 17,
          startAngle: 0,
          endAngle: Math.PI * 2,
        }));

      svg.append('path')
        .classed('pie', true)
        .attr('transform', 'translate(25,25)')
        .style('fill', props.color)
        .attr('d', d3.arc()({
          innerRadius: 15,
          outerRadius: 22,
          startAngle: (-Math.PI * 3) / 4,
          endAngle: Math.PI * 2 * (props.lookStat.value / props.totalLogs) - (Math.PI * 3) / 4,
        }));
    }
  };

  useEffect(updateSvg, [props]);

  return (
    <Box sx={lookCountRowWrapper}>
      <Box sx={lookCountRowSvgWrapper}>
        <svg
          id={`look-row-stat-${props.lookStat.name}`}
          height={dimensions.height}
          width={dimensions.width}
          ref={d3Container}
        />
      </Box>
      <Typography sx={lookCountRowLabel}>{capitalize(props.lookStat.name)}</Typography>
    </Box>
  );
}

export default LookCountRow;
