import {includes, map} from 'lodash';
import ActionLog from '../api/ActionLogs/ActionLog';
import FilterSettings from './FiliterSettings';

const filterLogs = (actionLogs: ActionLog[], filterSettings: FilterSettings): ActionLog[] => map(actionLogs, (log) => {
  log.inFilter = true;

  log = filterType(log, filterSettings.types);

  return log;
});

const filterType = (log: ActionLog, types: string[]): ActionLog => {
  log.inFilter = includes(types, log.type);
  return log;
};

export default {
  filterLogs,
};
