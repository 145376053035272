import * as d3 from 'd3';
import {filter, forEach, map, padStart} from 'lodash';
import ActionLog from '../api/ActionLogs/ActionLog';
import LogExtent from '../components/HeatMap/LogExtent';
import ActionType from '../api/Actions/ActionType';
import SentimentValue from '../api/SentimentLogs/SentimentValues';
import TrialVideo from '../api/TrialVideos/TrialVideo';
import SentimentLog from '../api/SentimentLogs/SentimentLog';
import SentimentStatistics from '../components/SentimentSummary/SentimentStatistics';
import LookStat from '../components/LookAnalysis/LookStat';
import FirstLookStat from '../components/LookAnalysis/FirstLookStat';

const outOpacity = 0.3;

const movementColorInfilter = d3.rgb(0, 174, 239);
const grabColorInfilter = d3.rgb(0, 200, 5);
const releaseColorInfilter = d3.rgb(100, 80, 0);
const lookColorInfilter = d3.rgb(255, 240, 0);
const commentColorInfilter = d3.rgb(255, 0, 255);
const buyColorInfilter = d3.rgb(228, 94, 37);
const findColorInfilter = d3.rgb(255, 0, 255);

const movementColorOutfilter = d3.rgb(0, 174, 239, outOpacity);
const grabColorOutfilter = d3.rgb(0, 200, 5, outOpacity);
const releaseColorOutfilter = d3.rgb(100, 80, 0, outOpacity);
const lookColorOutfilter = d3.rgb(255, 240, 0, outOpacity);
const commentColorOutfilter = d3.rgb(255, 0, 255, outOpacity);
const buyColorOutfilter = d3.rgb(228, 94, 37, outOpacity);
const findColorOutfilter = d3.rgb(255, 0, 255, outOpacity);

const positiveColor = d3.rgb(102, 106, 237);
const neutralColor = d3.rgb(182, 182, 191);
const negativeColor = d3.rgb(255, 60, 46);

const getLogExtent = (actionLogs: ActionLog[]): LogExtent => {
  const maxX = d3.max(actionLogs, (d) => d.x) || 0;
  const minX = d3.min(actionLogs, (d) => d.x) || 0;
  const maxY = d3.max(actionLogs, (d) => d.z) || 0;
  const minY = d3.min(actionLogs, (d) => d.z) || 0;

  return {maxX, minX, maxY, minY};
};

const getLogTimeExtent = (actionLogs: ActionLog[]): [Date, Date] => {
  const extent = d3.extent(actionLogs, (d) => new Date(d.timestamp));
  return (extent[0] && extent[1]) ? extent : [new Date(), new Date()];
};

const getVideoTimeExtent = (trialVideo: TrialVideo, duration: number): [Date, Date] => {
  const endTimestamp = trialVideo.startTime.getTime() + duration * 1000;
  return [trialVideo.startTime, new Date(endTimestamp)];
};

const getDuration = (actionLogs: ActionLog[]): number => {
  const extent = getLogTimeExtent(actionLogs);
  return (extent[1].getTime() - extent[0].getTime()) / 1000;
};

const getEvents = (actionLogs: ActionLog[], type: ActionType): ActionLog[] => filter(
  actionLogs,
  (actionLog) => actionLog.type === type,
);

const getColorByType = (type: ActionType, inFilter: boolean): string => {
  let color = d3.rgb(0, 0, 0);

  if (type === ActionType.Movement) {
    color = inFilter ? movementColorInfilter : movementColorOutfilter;
  } else if (type === ActionType.Grab) {
    color = inFilter ? grabColorInfilter : grabColorOutfilter;
  } else if (type === ActionType.Drop) {
    color = inFilter ? releaseColorInfilter : releaseColorOutfilter;
  } else if (type === ActionType.Look) {
    color = inFilter ? lookColorInfilter : lookColorOutfilter;
  } else if (type === ActionType.Comment) {
    color = inFilter ? commentColorInfilter : commentColorOutfilter;
  } else if (type === ActionType.Buy) {
    color = inFilter ? buyColorInfilter : buyColorOutfilter;
  } else if (type === ActionType.Find) {
    color = inFilter ? findColorInfilter : findColorOutfilter;
  }

  return color.toString();
};

const getSentenceColor = (sentimentValue?: string): string => {
  let color;
  if (sentimentValue === SentimentValue.Negative) {
    color = negativeColor;
  } else if (sentimentValue === SentimentValue.Positive) {
    color = positiveColor;
  } else {
    color = neutralColor;
  }

  return color.toString();
};

const convertToMins = (duration: number): string => {
  const seconds = duration % 60;
  const minutes = Math.floor(duration / 60);

  return `${minutes}:${padStart(`${seconds}`, 2, '0')}`;
};

const getDurationText = (duration: number): string => {
  const seconds = duration % 60;
  const minutes = Math.floor(duration / 60);

  return `${minutes}M ${padStart(`${seconds}`, 2, '0')}S`;
};

const groupSentimentByValue = (logs: SentimentLog[]): SentimentStatistics => {
  const groups = {
    counts: {
      positive: 0,
      neutral: 0,
      negative: 0,
    },
    averages: {
      positive: 0,
      neutral: 0,
      negative: 0,
    },
  };

  forEach(logs, (log) => {
    if (log.sentiment.value === 'POSITIVE') {
      groups.counts.positive++;
      groups.averages.positive += log.sentiment.Positive;
    } else if (log.sentiment.value === 'NEUTRAL') {
      groups.counts.neutral++;
      groups.averages.neutral += log.sentiment.Neutral;
    } else if (log.sentiment.value === 'NEGATIVE') {
      groups.counts.negative++;
      groups.averages.negative += log.sentiment.Negative;
    }
  });

  groups.averages.positive = (groups.counts.positive) ? groups.averages.positive / groups.counts.positive : 0;
  groups.averages.neutral = (groups.counts.neutral) ? groups.averages.neutral / groups.counts.neutral : 0;
  groups.averages.negative = (groups.counts.negative) ? groups.averages.negative / groups.counts.negative : 0;

  return groups;
};

const groupLookCountsByName = (logs: ActionLog[]): LookStat[] => {
  const groups = d3.rollups(logs, (D) => D.length, (log) => log.objectName);

  const stats = map(groups, (group) => ({name: group[0].toString(), value: group[1]}));
  return stats;
};

const groupByTotalDuration = (logs: ActionLog[]): LookStat[] => {
  const groups = d3.rollups(logs, (D) => d3.sum(D, (d) => d.duration), (log) => log.objectName);

  const stats = map(groups, (group) => ({name: group[0].toString(), value: group[1]}));
  return stats;
};

const groupByFirstLook = (logs: ActionLog[]): FirstLookStat[] => {
  const groups = d3.rollups(logs, (D) => d3.min(D, (d) => d.timestamp), (log) => log.objectName);

  const stats = map(groups, (group) => ({name: group[0].toString(), value: group[1] || new Date()}));
  return stats;
};

const lookColorScheme = d3.schemeDark2;

export {
  getLogExtent,
  getLogTimeExtent,
  getVideoTimeExtent,
  getEvents,
  getColorByType,
  getSentenceColor,
  convertToMins,
  getDuration,
  getDurationText,
  groupSentimentByValue,
  groupLookCountsByName,
  groupByTotalDuration,
  groupByFirstLook,
  lookColorScheme,
};
