import {panel} from '../../styles/styles';

const lookCountWrapper = {
  ...panel,
  display: 'flex',
  flex: '1 0',
  flexDirection: 'column',
  alignItems: 'stretch',
  padding: '1rem',
};

const lookCountHeader = {
  display: 'flex',
  flex: '0 1',
  flexDirection: 'row',
  padding: '.25rem',
  justifyContent: 'space-between',
};

const lookCountRowsWrapper = {
  display: 'flex',
  flex: '1 0',
  flexDirection: 'column',
  alignItems: 'stretch',
  overflowX: 'auto',
};

export {
  lookCountWrapper,
  lookCountHeader,
  lookCountRowsWrapper,
};
