const lookAnalysisWrapper = {
  display: 'flex',
  flex: '1 0',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: '1rem',
};

const lookAnalysisHeader = {
  display: 'flex',
  flex: '0 1',
  flexDirection: 'row',
  padding: '.25rem',
  justifyContent: 'space-between',
};

const lookAnalysisRow = {
  display: 'flex',
  flex: '1 0',
  flexDirection: 'row',
  alignItems: 'stretch',
  justifyContent: 'space-between',
  gap: '1rem',
};

export {
  lookAnalysisWrapper,
  lookAnalysisHeader,
  lookAnalysisRow,
};
