const TablePanel = {
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0',
  maxHeight: '400px',
};

const ControlContainer = {
  display: 'flex',
  flex: '1 0',
  gap: '0.25rem',
};

const TypeButtonOn = {
  alignItems: 'center',
  background: '#436833',
};

const TypeButtonOff = {
  background: '#83888E',
};

const TableWrapper = {
  display: 'flex',
  flex: '1 0',
  overflowY: 'auto',
  padding: '.25rem',
};

export {
  TablePanel,
  ControlContainer,
  TypeButtonOn,
  TypeButtonOff,
  TableWrapper,
};
