import {flex, panel} from '../../styles/styles';

const reportInfoPanel = {
  // ...panel,
  flex: '0 1',
  flexDirection: 'column',
  alignItems: 'stretch',
  // padding: '.25rem',
  // minWidth: '20rem',
};

const reportInfoRow = {
  display: 'flex',
  flex: '0 1',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& button': {
    display: 'flex',
  },
  '& h2': {
    display: 'flex',
    textAlign: 'center',
    fontSize: '2rem',
    fontWeight: 400,
    flex: '1 0 0',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    justifyContent: 'center',
  },
  '& h4': {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.25rem',
    fontWeight: 400,
    flex: '1 0 0',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};

const reportInfoGroup = {
  display: 'flex',
  flex: '0 1',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'space-between',
  textAlign: 'right',
};

const reportInfoDurationLabel = {
  display: 'flex',
  flex: '0 1',
  flexDirection: 'row',
  justifyContent: 'right',
};

const reportInfoEmail = {
  justifyContent: 'center',
};

export {
  reportInfoPanel,
  reportInfoRow,
  reportInfoGroup,
  reportInfoDurationLabel,
  reportInfoEmail,
};
