const PathConstants = {
  HOME: '/',
  AGENCY: '/agencies/:agencyId',
  AGENCIES: '/agencies',
  CLIENT: '/clients/:clientId',
  EXPERIMENTS: '/experiments/:experimentId?',
  LOGIN: '/login',
  SIGNUP: '/signup',
  REPORTS: '/reports/:trialId',
  VIDEO_UPLOAD: '/video-upload',
};

export default PathConstants;
