import {Box, Button, Input, Typography} from '@mui/material';
import React, {ChangeEvent, ReactNode, useEffect, useState} from 'react';
import {Send} from '@mui/icons-material';
import {find, map} from 'lodash';
import * as d3 from 'd3';
import PanelHeader from '../PanelHeader/PanelHeader';
import {videoSubmitFormHeader, videoSubmitFormPanel} from './VideoSubmitForm.styles';
import Scenario from '../../api/Scenarios/Scenario';
import SelectField from '../SelectField/SelectField';
import ScenarioRun from '../../api/ScenarioRuns/ScenarioRun';

type VideoSubmitFormProps = {
  scenarios: Scenario[];
  scenarioRuns: ScenarioRun[];
  onSubmit: (scenario: Scenario, file: File, startTime: Date) => any;
}

function VideoSubmitForm(props: VideoSubmitFormProps) {
  const [selectedScenario, selectScenario] = useState<Scenario | undefined>(undefined);
  const [selectedScenarioRun, selectScenarioRun] = useState<ScenarioRun | undefined>(undefined);
  const [selectedFile, selectFile] = useState<File | undefined>(undefined);
  const [startTime, setStartTime] = useState<Date | undefined>(undefined);

  useEffect(() => {
    if (selectedScenarioRun) {
      setStartTime(new Date(selectedScenarioRun?.startTime));
    }
  }, [selectedScenarioRun]);

  const fireSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.preventDefault();
    event.stopPropagation();

    if (selectedScenario && selectedFile && startTime) {
      props.onSubmit(selectedScenario, selectedFile, startTime);
    }
  };

  const setSelectedScenario = (id: string): void => {
    const scenarioToSelect = find(props.scenarios, (scenario) => scenario.id === id);
    const scenarioRunToSelect = find(props.scenarioRuns, (run) => run.scenarioId === id);
    selectScenario(scenarioToSelect);
    if (scenarioRunToSelect) {
      selectScenarioRun(scenarioRunToSelect);
    }
  };

  const setStartTimeWithFileName = (fileName: string[]): void => {
    const fileNameDate = fileName[fileName.length - 2].replace(/\D/g, '');

    const parser = d3.timeParse('%Y%m%d%H%M%S');
    const date = parser(fileNameDate) || undefined;

    setStartTime(date);
  };

  const setSelectedFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files?.length > 0) {
      const file = event.target.files[0];

      if (!startTime) {
        const fileName = file.name.split('.');
        setStartTimeWithFileName(fileName);
      }

      selectFile(file);
    }
  };

  const getScenarioDropdown = (): ReactNode => (
    <SelectField
      items={map(props.scenarios, (scenario) => ({value: scenario.id || '', name: scenario.sceneName}))}
      selectedValue={selectedScenario?.id || ''}
      label="Scene"
      onChange={(id) => setSelectedScenario(id)}
    />
  );

  const isSubmitEnabled = (): boolean => !!(selectedScenario && selectedFile && startTime);

  return (
    <Box sx={videoSubmitFormPanel}>
      <PanelHeader
        sx={videoSubmitFormHeader}
        title="Trial Code"
      />
      <Box>
        <Typography variant="h3" color="primary">StartTime: {startTime?.toISOString() || ''}</Typography>
      </Box>
      <Box>
        {getScenarioDropdown()}
        <Input
          type="file"
          id="code"
          name="Code"
          fullWidth
          // variant="filled"
          required
          onChange={(event: ChangeEvent<HTMLInputElement>) => setSelectedFile(event)}
        />
        <Button
          variant="contained"
          startIcon={<Send />}
          onClick={(event) => fireSubmit(event)}
          disabled={!isSubmitEnabled()}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}

export default VideoSubmitForm;
