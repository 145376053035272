import React, {ReactNode, useEffect, useState} from 'react';
import {Box, MenuItem, Select, SelectChangeEvent, TextField} from '@mui/material';
import {map, startCase} from 'lodash';
import Scene from '../../api/Scenes/Scene';
import PanelHeader from '../PanelHeader/PanelHeader';
import {
  configureSceneInput,
  configureScenePanel,
  configureScenePanelBody,
  configureScenePanelHeader,
  configureSceneRow,
} from './ConfigureScenePanel.Styles';
import PanelControlsFooter from '../PanelControlsFooter/PanelControlsFooter';
import SceneType from '../../api/Scenes/SceneType';

type ConfigureScenePanelProps = {
  scene: Scene,
  onCancel: () => any;
  onSave: (scene: Scene) => any;
}

function ConfigureScenePanel(props: ConfigureScenePanelProps) {
  const [name, setName] = useState<string>(props.scene.name || '');
  const [url, setUrl] = useState<string>(props.scene.url || '');
  const [assetName, setAssetName] = useState<string>(props.scene.assetName || '');
  const [imagePath, setImagePath] = useState<string>(props.scene.imageStringBase64 || '');
  const [imageMimeType, setImageMimeType] = useState<string>(props.scene.imageStringBase64 || '');
  const [minX, setMinX] = useState<Number>(props.scene.minX || 0);
  const [minY, setMinY] = useState<Number>(props.scene.minY || 0);
  const [maxX, setMaxX] = useState<Number>(props.scene.maxX || 0);
  const [maxY, setMaxY] = useState<Number>(props.scene.maxY || 0);
  const [type, setType] = useState<SceneType>(props.scene.type || SceneType.HeatMap);

  useEffect(() => {
    setName(props.scene.name || '');
    setUrl(props.scene.url || '');
    setAssetName(props.scene.assetName || '');
    setImagePath(props.scene.imagePath || '');
    setImageMimeType(props.scene.imageMimeType || '');
    setMinX(props.scene.minX || 0);
    setMinY(props.scene.minY || 0);
    setMaxX(props.scene.maxX || 0);
    setMaxY(props.scene.maxY || 0);
    setType(props.scene.type || SceneType.HeatMap);
  }, [props.scene]);

  const fireSave = () => {
    const sceneToSave = {
      id: props.scene.id,
      clientId: props.scene.clientId,
      name,
      url,
      assetName,
      imageMimeType,
      imagePath,
      minX,
      minY,
      maxX,
      maxY,
      type,
    };

    props.onSave(sceneToSave);
  };

  const saveDisabled = ():boolean => (
    name === '' || name === 'New Scene'
  );

  const fireCancel = () => {
    props.onCancel();
  };

  const getSceneTypeMenuItem = (sceneType: string): ReactNode => (
    <MenuItem key={sceneType} value={sceneType}>{startCase(sceneType)}</MenuItem>
  );

  const getSceneTypesMenuItems = (): ReactNode[] => {
    const sceneTypes = Object.values(SceneType);
    return map(sceneTypes, (sceneType) => getSceneTypeMenuItem(sceneType));
  };

  return (
    <Box sx={configureScenePanel}>
      <PanelHeader
        sx={configureScenePanelHeader}
        title="Configure Scene"
      />
      <Box sx={configureScenePanelBody}>
        <Box sx={configureSceneRow}>
          <TextField
            error={name === '' || name === 'New Scene'}
            sx={configureSceneInput}
            variant="filled"
            label="Scene Name"
            value={name}
            required
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setName(event.target.value);
            }}
          />
        </Box>
        <Box sx={configureSceneRow}>
          <TextField
            sx={configureSceneInput}
            variant="filled"
            label="Scene URL"
            value={url}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setUrl(event.target.value);
            }}
          />
        </Box>
        <Box sx={configureSceneRow}>
          <TextField
            sx={configureSceneInput}
            variant="filled"
            label="Scene Addressable Name"
            value={assetName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setAssetName(event.target.value);
            }}
          />
        </Box>
        <Box sx={configureSceneRow}>
          <TextField
            sx={configureSceneInput}
            variant="filled"
            label="Image Path"
            value={imagePath}
            fullWidth
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setImagePath(event.target.value);
            }}
          />
        </Box>
        <Box sx={configureSceneRow}>
          <TextField
            sx={configureSceneInput}
            variant="filled"
            label="Min X"
            value={minX}
            required
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setMinX(Number(event.target.value));
            }}
          />
          <TextField
            sx={configureSceneInput}
            variant="filled"
            label="Min Y"
            value={minY}
            required
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setMinY(Number(event.target.value));
            }}
          />
          <TextField
            sx={configureSceneInput}
            variant="filled"
            label="Max X"
            value={maxX}
            required
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setMaxX(Number(event.target.value));
            }}
          />
          <TextField
            sx={configureSceneInput}
            variant="filled"
            label="Max Y"
            value={maxY}
            required
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setMaxY(Number(event.target.value));
            }}
          />
        </Box>
        <Box sx={configureSceneRow}>
          <Select
            sx={configureSceneInput}
            variant="filled"
            value={type}
            label="Type"
            onChange={(event:SelectChangeEvent<SceneType>) => {
              setType(event.target.value as SceneType);
            }}
          >
            {getSceneTypesMenuItems()}
          </Select>
        </Box>
      </Box>
      <PanelControlsFooter
        onCancel={() => fireCancel()}
        onSave={() => fireSave()}
        saveDisabled={saveDisabled}
      />
    </Box>
  );
}

export default ConfigureScenePanel;
