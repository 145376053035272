import {panel} from '../../styles/styles';
import panelHeader from '../PanelHeader/PanelHeader.Style';

const videoSubmitFormPanel = {
  ...panel,
  alignItems: 'stretch',
  flex: '1 0',
};

const videoSubmitFormHeader = {
  ...panelHeader,
  justifyContent: 'space-between',
};

const formRow = {
  display: 'flex',
  flex: '0 1',
  justifyContent: 'space-between',
};

export {
  videoSubmitFormPanel,
  videoSubmitFormHeader,
  formRow,
};
