import {panel} from '../../styles/styles';

const lookDurationWrapper = {
  ...panel,
  display: 'flex',
  flex: '1 0',
  flexDirection: 'column',
  alignItems: 'stretch',
  padding: '1rem',
  '& svg': {
    margin: 'auto',
  },
};

const lookDurationHeader = {
  display: 'flex',
  flex: '0 1',
  flexDirection: 'row',
  padding: '.25rem',
  justifyContent: 'space-between',
};

const lookDurationRowsWrapper = {
  display: 'flex',
  flex: '1 0',
  flexDirection: 'column',
  alignItems: 'stretch',
  overflowX: 'auto',
};

export {
  lookDurationWrapper,
  lookDurationHeader,
  lookDurationRowsWrapper,
};
