import React, {ReactNode} from 'react';
import {Box, Typography} from '@mui/material';
import {capitalize, forEach} from 'lodash';
import * as ReportUtils from '../../Utils/ReportUtils';
import SentimentLog from '../../api/SentimentLogs/SentimentLog';
import {
  countItem,
  selectedLogWrapper,
  sentimentCountsWrapper,
  sentimentLogRow,
  sentimentSummaryContent,
  sentimentSummaryRow,
  sentimentSummaryWrapper,
} from './SentimentSummary.Styles';
import SentimentValue from '../../api/SentimentLogs/SentimentValues';
import Sentiment from '../../api/SentimentLogs/Sentiment';

type SentimentSummaryProps = {
  startTime: Date;
  sentimentLogs: SentimentLog[];
  selectedSentiment?: SentimentLog;
};

function SentimentSummary(props: SentimentSummaryProps) {
  const getPercentSentimentPercents = (): string => {
    const timeValues = {
      total: 0,
      positive: 0,
      neutral: 0,
      negative: 0,
    };

    forEach(props.sentimentLogs, (log) => {
      timeValues.total += log.transcript.duration;
      if (log.sentiment.value === SentimentValue.Positive) {
        timeValues.positive += log.transcript.duration;
      } else if (log.sentiment.value === SentimentValue.Negative) {
        timeValues.negative += log.transcript.duration;
      } else if (log.sentiment.value === SentimentValue.Neutral) {
        timeValues.neutral += log.transcript.duration;
      }
    });

    const positivePerc = Math.floor((timeValues.positive / timeValues.total) * 100);
    const neutralPerc = Math.floor((timeValues.neutral / timeValues.total) * 100);

    return `linear-gradient(
        to right, #666aed, #666aed ${positivePerc}%,
        #b6b6bf ${positivePerc}%, #b6b6bf ${positivePerc + neutralPerc}%,
        #ff3c2e ${positivePerc + neutralPerc}%, #ff3c2e
      )`;
  };

  const getBarStyles = () => ({
    display: 'flex',
    flex: '1 1',
    height: '2rem',
    background: getPercentSentimentPercents(),
  });

  const getEventTime = (created: Date): string => {
    const seconds = (new Date(created).getTime() - props.startTime.getTime()) / 1000;
    return ReportUtils.convertToMins(seconds);
  };

  const getSentimentValue = (sentiment: Sentiment): string => {
    let value = 0;
    if (sentiment.value === 'POSITIVE') {
      value = sentiment.Positive;
    } else if (sentiment.value === 'NEUTRAL') {
      value = sentiment.Neutral;
    } else if (sentiment.value === 'NEGATIVE') {
      value = sentiment.Negative;
    }

    return `${capitalize(sentiment.value)} value: ${value.toFixed(3)}`;
  };

  const getSelectedLogText = (): ReactNode | undefined => {
    let logText = (
      <Box sx={selectedLogWrapper}>
        <Typography variant="subtitle2">Transcript</Typography>
      </Box>
    );

    if (props.selectedSentiment && props.selectedSentiment.transcript.text) {
      const created = props.selectedSentiment.created;
      const sentiment = props.selectedSentiment.sentiment;

      logText = (
        <Box sx={selectedLogWrapper}>
          <Typography variant="subtitle2">Transcript</Typography>
          <Typography variant="h4">&quot;{props.selectedSentiment.transcript.text}&quot;</Typography>
          <Box sx={sentimentLogRow}>
            <Typography>{getSentimentValue(sentiment)}</Typography>
            <Typography>Time: {getEventTime(created)}</Typography>
          </Box>
        </Box>
      );
    }

    return logText;
  };

  const getSentimentCounts = (): ReactNode => {
    const stats = ReportUtils.groupSentimentByValue(props.sentimentLogs);
    return (
      <Box sx={sentimentSummaryRow}>
        <Box sx={countItem}>
          <Typography variant="h3" sx={{color: '#666aed'}}>{stats.counts.positive}</Typography>
          <Typography variant="subtitle1">Positive</Typography>
          <Typography variant="subtitle2">Ave Value: {stats.averages.positive.toFixed(3)}</Typography>
        </Box>
        <Box sx={countItem}>
          <Typography variant="h3" sx={{color: '#b6b6bf'}}>{stats.counts.neutral}</Typography>
          <Typography variant="subtitle1">Neutral</Typography>
          <Typography variant="subtitle2">Ave Value: {stats.averages.neutral.toFixed(3)}</Typography>
        </Box>
        <Box sx={countItem}>
          <Typography variant="h3" sx={{color: '#ff3c2e'}}>{stats.counts.negative}</Typography>
          <Typography variant="subtitle1">Negative</Typography>
          <Typography variant="subtitle2">Ave Value: {stats.averages.negative.toFixed(3)}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={sentimentSummaryWrapper}>
      <Typography variant="h3">Sentiment Analysis</Typography>

      <Box sx={sentimentSummaryContent}>
        <Box>

          <Box sx={sentimentSummaryRow}>
            <Box sx={getBarStyles()} />
          </Box>
          <Box sx={sentimentCountsWrapper}>
            {getSentimentCounts()}
          </Box>
        </Box>
        {getSelectedLogText()}
      </Box>
    </Box>
  );
}

export default SentimentSummary;
