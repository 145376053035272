import {Box, Typography} from '@mui/material';
import React, {useEffect, useRef} from 'react';
import {capitalize, forEach, map} from 'lodash';
import * as d3 from 'd3';
import * as ReportUtils from '../../Utils/ReportUtils';
import {lookDurationHeader, lookDurationWrapper} from './LookDuration.Styles';
import ActionLog from '../../api/ActionLogs/ActionLog';
import LookStat from '../LookAnalysis/LookStat';

type LookDurationProps = {
  lookLogs: ActionLog[],
  lookNames: string[],
};

function LookDuration(props: LookDurationProps) {
  const d3Container = useRef<SVGSVGElement>(null);

  const dimensions = {
    height: 300,
    width: 300,
  };

  const updateSvg = () => {
    if (d3Container.current) {
      const data = ReportUtils.groupByTotalDuration(props.lookLogs);
      const color = d3.scaleOrdinal(props.lookNames, ReportUtils.lookColorScheme);

      const height = Math.min(dimensions.width, 500);
      const radius = Math.min(dimensions.width, height) / 2;
      const svg = d3.select(`#${d3Container?.current.id}`);

      svg.selectAll('.duration').remove();
      svg.attr('viewBox', [-dimensions.width / 2, -height / 2, dimensions.width, height]);

      const arc = d3.arc<d3.PieArcDatum<LookStat>>()
        .innerRadius(radius * 0.5)
        .outerRadius(radius - 1);

      const pie = d3.pie<LookStat>()
        .padAngle(5 / radius)
        .sort((a, b) => d3.descending(a.value, b.value))
        .value((d) => d.value);

      svg.append('g')
        .selectAll()
        .data(pie(data))
        .join('path')
        .attr('fill', (d) => color(d.data.name))
        .attr('d', arc)
        .append('title')
        .classed('duration', true)
        .text((d) => `${capitalize(d.data.name)}: ${d.data.value.toLocaleString()}s`);

      svg.append('g')
        .classed('duration', true)
        .attr('font-family', 'sans-serif')
        .attr('font-size', 12)
        .attr('text-anchor', 'middle')
        .selectAll()
        .data(pie(data))
        .join('text')
        .attr('transform', (d) => `translate(${arc.centroid(d)})`)
        .call((text) => text.append('tspan')
          .attr('y', '-0.4em')
          .attr('font-weight', 'bold')
          .text((d) => capitalize(d.data.name)))
        .call((text) => text.filter((d) => (d.endAngle - d.startAngle) > 0.25).append('tspan')
          .attr('x', 0)
          .attr('y', '0.7em')
          .attr('fill-opacity', 0.7)
          .text((d) => d.data.value.toLocaleString('en-US')));
    }
  };

  const getTotalDuration = () => {
    let total = 0;
    forEach(props.lookLogs, (log) => { total += Number(log.duration); });

    return total.toFixed(2);
  };

  useEffect(updateSvg, [props]);

  return (
    <Box sx={lookDurationWrapper}>
      <Box sx={lookDurationHeader}>
        <Typography variant="h3">Look Duration</Typography>
        <Typography variant="h3">Total: {getTotalDuration()}s</Typography>
      </Box>
      <svg
        id="duration-pie"
        height={dimensions.height}
        width={dimensions.width}
        ref={d3Container}
      />
    </Box>
  );
}

export default LookDuration;
