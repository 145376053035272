import React, {useRef, useEffect} from 'react';
import * as d3 from 'd3';
import {Box, duration, Typography} from '@mui/material';
import {capitalize} from 'lodash';
import FirstLookStat from '../LookAnalysis/FirstLookStat';
import {firstLookRowLabel, firstLookRowSvgWrapper, firstLookRowWrapper} from './FirstLookRow.Styles';

type FirstLookRowProps = {
  firstLook: FirstLookStat;
  startTime: Date;
  color: string;
  duration: number;
};

function FirstLookRow(props: FirstLookRowProps) {
  const d3Container = useRef<SVGSVGElement>(null);
  const dimensions = {
    height: 50,
    width: 150,
  };

  const textColor = d3.rgb(0, 0, 0).toString();
  const lookColorInfilter = d3.rgb(0, 200, 5).toString();
  const lookColorOutFilter = d3.rgb(150, 150, 150).toString();

  const updateSvg = () => {
    if (d3Container.current) {
      const xScale = d3.scaleLinear()
        .domain([0, props.duration])
        .range([0, dimensions.width]);

      const svg = d3.select(`#${d3Container?.current.id}`);
      svg.selectAll('.first-look-rect').remove();

      const firstLookTime = (props.firstLook.value.getTime() - props.startTime.getTime()) / 1000;

      svg.append('text')
        .classed('first-look-rect', true)
        .attr('x', dimensions.width / 2)
        .attr('y', dimensions.height - 17)
        .style('text-anchor', 'middle')
        .style('font-size', 20)
        .style('fill', props.color)
        .text(`${firstLookTime.toFixed(2)}s`);

      svg.append('rect')
        .classed('first-look-rect', true)
        .attr('x', 0)
        .attr('y', 40)
        .attr('height', 10)
        .attr('width', xScale(firstLookTime))
        .style('fill', props.color);
    }
  };

  useEffect(updateSvg, [props]);

  return (
    <Box sx={firstLookRowWrapper}>
      <Box sx={firstLookRowSvgWrapper}>
        <svg
          id={`first-look-row-stat-${props.firstLook.name}`}
          height={dimensions.height}
          width={dimensions.width}
          ref={d3Container}
        />
      </Box>
      <Typography sx={firstLookRowLabel}>{capitalize(props.firstLook.name)}</Typography>
    </Box>
  );
}

export default FirstLookRow;
