import {cloneDeep, map} from 'lodash';
import tupeloApi from '../helpers';
import TrialVideo from './TrialVideo';

const processTrialVideo = (video: TrialVideo): TrialVideo => {
  const trialVideo = cloneDeep(video);
  trialVideo.startTime = new Date(video.startTime);
  trialVideo.created = (video.created) ? new Date(video.created) : undefined;
  return trialVideo;
};

const getVideosForTrial = async (trialId: string): Promise<TrialVideo[]> => {
  const result = await tupeloApi.get(`/trials/${trialId}/videos`);
  const videoData = result.data as TrialVideo[];
  return map(videoData, (video) => processTrialVideo(video));
};

const postVideoForTrial = async (trialVideoData: TrialVideo, file: File): Promise<TrialVideo> => {
  const formData = new FormData();
  formData.append('agencyId', trialVideoData.agencyId);
  formData.append('trialId', trialVideoData.trialId);
  formData.append('scenarioId', trialVideoData.scenarioId);
  formData.append('startTime', trialVideoData.startTime.toISOString());
  formData.append('file', file);

  const result = await tupeloApi.post(`/trial-videos/${trialVideoData.trialId}`, formData);
  return result.data as TrialVideo;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getVideosForTrial,
  postVideoForTrial,
};
