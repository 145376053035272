import React, {ReactNode, Suspense, useEffect, useState} from 'react';
import {Box} from '@mui/material';
import {AxiosError} from 'axios';
import {appWrapper, contentWrapper} from '../../styles/styles';
import NavBar from '../NavBar/NavBar';
import Trial from '../../api/Trials/Trial';
import Scenario from '../../api/Scenarios/Scenario';
import VideoCodeForm from '../VideoCodeForm/VideoCodeForm';
import VideoSubmitForm from '../VideoSubmitForm/VideoSubmitForm';
import apiTrials from '../../api/Trials/apiTrials';
import Experiment from '../../api/Experiments/Experiment';
import apiExperiments from '../../api/Experiments/apiExperiments';
import apiScenarios from '../../api/Scenarios/apiScenarios';
import TrialVideo from '../../api/TrialVideos/TrialVideo';
import apiTrialVideos from '../../api/TrialVideos/apiTrialVideos';
import ScenarioRun from '../../api/ScenarioRuns/ScenarioRun';
import apiScenarioRun from '../../api/ScenarioRuns/apiScenarioRun';

function VideoUploadPage() {
  const agencyId = process.env.REACT_APP_AGENCY_ID;
  const [selectedTrial, selectTrial] = useState<Trial | undefined>(undefined);
  const [experiment, setExperiment] = useState<Experiment | undefined>(undefined);
  const [scenarios, setScenarios] = useState<Scenario[]>([] as Scenario[]);
  const [scenarioRuns, setScenarioRuns] = useState<ScenarioRun[]>([] as ScenarioRun[]);

  useEffect(() => {
    if (selectedTrial && selectedTrial.id) {
      apiExperiments.getExperiment(selectedTrial.experimentId).then((response) => {
        setExperiment(response);
      });

      apiScenarioRun.getScenarioRunsForTrial(selectedTrial.id).then((response) => {
        setScenarioRuns(response);
      });
    }
  }, [selectedTrial]);

  useEffect(() => {
    if (experiment && experiment.id) {
      apiScenarios.getVideoScenariosForExperiment(experiment.id).then((response) => {
        setScenarios(response);
        if (response.length === 0) {
          // eslint-disable-next-line no-alert
          alert('Trial contains no video scenarios, please enter another code');
        }
      });
    }
  }, [experiment]);

  const submitVideoFile = (scenario: Scenario, file: File, startTime: Date): void => {
    // const startTime = selectedTrial?.completed || new Date();
    const videoData = {
      agencyId,
      trialId: selectedTrial?.id,
      scenarioId: scenario.id,
      startTime: new Date(startTime),
    } as TrialVideo;

    apiTrialVideos.postVideoForTrial(videoData, file).then((response) => {
      if (response) {
        console.log('trial_video', response);
        selectTrial(undefined);
        setExperiment(undefined);
        setScenarios([]);
      }
    }).catch((error) => {
      // eslint-disable-next-line no-alert
      alert('Video file not accepted, please try again');
    });
  };

  const submitCode = (code: string): void => {
    apiTrials.getTrialFromCode(code).then((response) => {
      selectTrial(response);
    }).catch((error: AxiosError) => {
      if (error.response?.status === 404) {
        // eslint-disable-next-line no-alert
        alert(`No trial exists for code ${code}, please enter another code`);
      }
    });
  };

  const getForm = (): ReactNode => {
    let form;

    if (selectedTrial && scenarios.length > 0) {
      form = (
        <VideoSubmitForm
          scenarios={scenarios}
          scenarioRuns={scenarioRuns}
          onSubmit={(scenario, file, startTime) => submitVideoFile(scenario, file, startTime)}
        />
      );
    } else {
      form = <VideoCodeForm onSubmit={(code) => submitCode(code)} />;
    }

    return form;
  };

  return (
    <Suspense>
      <Box sx={appWrapper}>
        <NavBar title="Video Upload" />
        <Box component="main" sx={contentWrapper}>
          {getForm()}
        </Box>
      </Box>
    </Suspense>
  );
}

export default VideoUploadPage;
