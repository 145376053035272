import {Box, Typography} from '@mui/material';
import React from 'react';
import {map} from 'lodash';
import * as d3 from 'd3';
import * as ReportUtils from '../../Utils/ReportUtils';
import ActionLog from '../../api/ActionLogs/ActionLog';
import {lookCountHeader, lookCountRowsWrapper, lookCountWrapper} from './LookCounts.Styles';
import LookCountRow from '../LookCountRow/LookCountRow.d3';
import LookStat from '../LookAnalysis/LookStat';

type LookCountsProps = {
  lookLogs: ActionLog[],
  lookNames: string[],
};

function LookCounts(props: LookCountsProps) {
  const getLookRow = (lookStat: LookStat, index: number) => {
    let color = d3.rgb(0, 200, 5).toString();
    if (props.lookNames.length > 0) {
      const lookColorScale = d3.scaleOrdinal(props.lookNames, ReportUtils.lookColorScheme);
      color = lookColorScale(lookStat.name);
    }

    return (
      <LookCountRow
        key={index}
        totalLogs={props.lookLogs.length}
        lookStat={lookStat}
        color={color}
      />
    );
  };

  const getLookRows = () => {
    const lookCountStats = ReportUtils.groupLookCountsByName(props.lookLogs);
    const sortedLooks = lookCountStats.sort((a, b) => d3.descending(a.value, b.value));
    return map(sortedLooks, (stat, i) => getLookRow(stat, i));
  };

  return (
    <Box sx={lookCountWrapper}>
      <Box sx={lookCountHeader}>
        <Typography variant="h3">Look Count</Typography>
        <Typography variant="h3">Total: {props.lookLogs.length}</Typography>
      </Box>
      <Box sx={lookCountRowsWrapper}>
        {getLookRows()}
      </Box>
    </Box>
  );
}

export default LookCounts;
