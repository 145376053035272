import React, {useEffect, useRef, useState} from 'react';
import {Box} from '@mui/material';
import TrialVideo from '../../api/TrialVideos/TrialVideo';

const reportVideoWrapper = {
  display: 'flex',
  flex: '0 1',
  background: '#000000',
  // padding: '1rem',
  alignItems: 'center',
  justifyContent: 'center',
};

type ReportVideoProps = {
  newVideoTime: number;
  videoData: TrialVideo;
  onVideoUpdate: (time: number) => any;
  onVideoLoaded: (duration: number) => any;
};

function ReportVideo(props: ReportVideoProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [metadataLoaded, setMetadataLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (metadataLoaded && videoRef.current) {
      let newVideoTime = props.newVideoTime;
      if (props.newVideoTime < 0) {
        newVideoTime = 0;
      } else if (videoRef.current?.duration && props.newVideoTime > videoRef.current?.duration) {
        newVideoTime = videoRef.current.duration;
      }

      videoRef.current.currentTime = newVideoTime;
    }
  }, [props.newVideoTime]);

  const onVideoUpdate = (event: React.SyntheticEvent<HTMLVideoElement, Event>): void => {
    if (metadataLoaded) {
      event.preventDefault();
      const currentVideoTime = videoRef.current?.currentTime;
      props.onVideoUpdate(currentVideoTime || 0);
    }
  };

  const onMetadataLoaded = () => {
    const duration = videoRef.current?.duration;
    setMetadataLoaded(true);
    props.onVideoLoaded(duration || 0);
  };

  return (
    <Box sx={reportVideoWrapper}>
      <video
        id="tupelo-video"
        controls
        src={props.videoData.processedUrl || props.videoData.videoUrl}
        onTimeUpdate={(event) => onVideoUpdate(event)}
        width="500"
        ref={videoRef}
        onLoadedMetadata={() => onMetadataLoaded()}
      />
    </Box>
  );
}

export default ReportVideo;
