import React from 'react';
import {Box, IconButton, Typography} from '@mui/material';
import {ArrowBackIosNew} from '@mui/icons-material';
import dayjs from 'dayjs';
import {startCase} from 'lodash';
import Trial from '../../api/Trials/Trial';
import {
  reportInfoDurationLabel,
  reportInfoEmail,
  reportInfoGroup,
  reportInfoPanel,
  reportInfoRow,
} from './ReportInfoStyles';

type ReportInfoProps = {
  trial?: Trial;
  experimentName?: string;
  duration: string;
  onReturn: () => any;
};

function ReportInfo(props: ReportInfoProps) {
  const onFireReturn = () => {
    props.onReturn();
  };

  return (
    <Box sx={reportInfoPanel}>
      <Box sx={reportInfoRow}>
        <Box>
          <IconButton aria-label="back" onClick={() => onFireReturn()}>
            <ArrowBackIosNew />
          </IconButton>
          <Typography variant="h4">{startCase(props.experimentName)}</Typography>
        </Box>
        <Typography variant="h2">{startCase(props.trial?.name)}</Typography>
        <Box sx={reportInfoGroup}>
          <Typography sx={reportInfoEmail} variant="subtitle2">{props.trial?.email}</Typography>
          <Typography>{dayjs(props.trial?.completed || new Date()).format('MMM D')}</Typography>
          <Box sx={reportInfoDurationLabel}>
            <Typography variant="subtitle2">{props.duration}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ReportInfo;
