import React, {ReactNode, useEffect, useState} from 'react';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {cloneDeep, filter, includes, map} from 'lodash';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ActionLog from '../../api/ActionLogs/ActionLog';
import {convertToMins, getLogTimeExtent} from '../../Utils/ReportUtils';
import Action from '../../api/Actions/Action';
import {
  TablePanel,
  ControlContainer,
  TypeButtonOff,
  TypeButtonOn,
  TableWrapper,
} from './ReportActionLogsTable.Styles';

type ReportActionLogsTableProps = {
  testLabel: 'A' | 'B';
  actionLogs: ActionLog[];
  actions: Action[];
  typeSettings: string[];
  onTypesChange: (types: string[]) => any;
};

function ReportActionLogsTable(props: ReportActionLogsTableProps) {
  const [startTime, setStartTime] = useState<Date | undefined>(undefined);

  useEffect(() => {
    const stime = getLogTimeExtent(props.actionLogs)[0];
    setStartTime(stime);
  }, [props.actionLogs]);

  const getTimeFromStartString = (time: Date): string => {
    const millis = time.getTime() - (startTime?.getTime() || 0);
    return convertToMins(millis / 1000);
  };

  const toggleTypeFilter = (actionId: string): void => {
    let updatedTypeSettings = cloneDeep(props.typeSettings);
    const inFilter = includes(props.typeSettings, actionId);
    if (inFilter) {
      updatedTypeSettings = filter(updatedTypeSettings, (type) => type !== actionId);
    } else {
      updatedTypeSettings.push(actionId);
    }

    props.onTypesChange(updatedTypeSettings);
  };

  // eslint-disable-next-line arrow-body-style
  const getTypeToggle = (action: Action): ReactNode => {
    const inFilter = includes(props.typeSettings, action.id);

    const icon = inFilter ? <CheckIcon /> : <CloseIcon />;
    return (
      <Button
        key={action.id}
        variant="contained"
        sx={inFilter ? TypeButtonOn : TypeButtonOff}
        onClick={() => toggleTypeFilter(action.id)}
      >
        {action.id}
        {icon}
      </Button>
    );
  };

  const getControlToggles = (): ReactNode[] => map(props.actions, (action) => getTypeToggle(action));

  const getLogRow = (log: ActionLog): ReactNode => (
    <TableRow
      key={log.id}
      sx={{'&:last-child td, &:last-child th': {border: 0}}}
    >
      <TableCell component="th" scope="row">
        {log.type}
      </TableCell>
      <TableCell align="right">{getTimeFromStartString(new Date(log.timestamp))}</TableCell>
      <TableCell align="right">{log.objectName}</TableCell>
      <TableCell align="right">{Number(log.x).toFixed(3)}</TableCell>
      <TableCell align="right">{Number(log.y).toFixed(3)}</TableCell>
      <TableCell align="right">{Number(log.z).toFixed(3)}</TableCell>
    </TableRow>
  );

  const getLogRows = (): ReactNode[] => {
    const filteredLogs = filter(props.actionLogs, (log) => log.inFilter);
    return map(filteredLogs, (log) => getLogRow(log));
  };

  return (
    <Box sx={TablePanel}>
      <Typography variant="h4">
        Scenario: {props.testLabel}
      </Typography>
      <Box>
        <Box sx={ControlContainer}>{getControlToggles()}</Box>
      </Box>
      <Box sx={TableWrapper}>
        <TableContainer component={Paper}>
          <Table sx={{minWidth: 650}} size="small" aria-label="a dense table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell align="right">Time</TableCell>
                <TableCell align="right">Name</TableCell>
                <TableCell align="right">x</TableCell>
                <TableCell align="right">y</TableCell>
                <TableCell align="right">z</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{maxHeight: '600px'}}>
              {getLogRows()}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}

export default ReportActionLogsTable;
