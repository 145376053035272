import {map} from 'lodash';
import tupeloApi from '../helpers';
import ActionLog from './ActionLog';

const getActionLogsForTrial = async (trialId: string): Promise<ActionLog[]> => {
  const result = await tupeloApi.get(`/trials/${trialId}/action-logs`);

  return map(result.data, (log) => {
    log.inFilter = true;
    log.timestamp = new Date(log.timestamp);
    return log;
  }) as ActionLog[];
};

export default {getActionLogsForTrial};
