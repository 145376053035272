import {useNavigate, useParams} from 'react-router-dom';
import React, {ReactNode, Suspense, useEffect, useState} from 'react';
import {Box, Typography} from '@mui/material';
import {cloneDeep, filter, find, findIndex, forEach, map} from 'lodash';
import {ScaleOrdinal} from 'd3';
import {Secure} from '../HOC/Secure';
import HeatMap from '../HeatMap/HeatMap.D3';
import Timeline from '../Timeline/Timeline.d3';
import SentimentLog from '../../api/SentimentLogs/SentimentLog';
import NavBar from '../NavBar/NavBar';
import {appWrapper} from '../../styles/styles';
import apiTrials from '../../api/Trials/apiTrials';
import Trial from '../../api/Trials/Trial';
import Experiment from '../../api/Experiments/Experiment';
import Scenario from '../../api/Scenarios/Scenario';
import apiExperiments from '../../api/Experiments/apiExperiments';
import apiScenarios from '../../api/Scenarios/apiScenarios';
import ActionLog from '../../api/ActionLogs/ActionLog';
import apiSentimentLogs from '../../api/SentimentLogs/apiSentimentLogs';
import apiActionLogs from '../../api/ActionLogs/apiActionLogs';
import apiScenes from '../../api/Scenes/apiScenes';
import Scene from '../../api/Scenes/Scene';
import * as ReportUtils from '../../Utils/ReportUtils';
import {
  ReportContentWrapper,
  heatMapWrapper,
  reportLookAnalysisWrapper,
  reportPageAnalysisColumn,
  reportPageDataColumn,
  reportScenarioRow,
  reportScenarioRowGraphs,
  reportScenarioRowTable,
  reportScenarioSummaryRow,
  reportSummaryRow,
  reportVideoWrapper,
  scenarioSummariesWrapper,
} from './ReportPage.Styles';
import ReportInfo from '../ReportInfo/ReportInfo';
import ReportScenarioSummary from '../ReportScenarioSummary/ReportScenarioSummary';
import {getDuration, getDurationText} from '../../Utils/ReportUtils';
import ReportActionLogsTable from '../ReportActionLogsTable/ReportActionLogsTable';
import FilterSettings from '../../Utils/FiliterSettings';
import FilterUtil from '../../Utils/FilterUtil';
import Action from '../../api/Actions/Action';
import apiActions from '../../api/Actions/apiActions';
import TrialVideo from '../../api/TrialVideos/TrialVideo';
import apiTrialVideos from '../../api/TrialVideos/apiTrialVideos';
import SceneType from '../../api/Scenes/SceneType';
import ReportVideo from '../ReportVideo/ReportVideo';
import ActionType from '../../api/Actions/ActionType';
import LookAnalysis from '../LookAnalysis/LookAnalysis';

function ReportPage() {
  const {trialId} = useParams();
  const navigate = useNavigate();

  const [selectedTrial, setSelectedTrial] = useState<Trial | undefined>(undefined);
  const [experiment, setExperiment] = useState<Experiment | undefined>(undefined);
  const [scenarios, setScenarios] = useState<Scenario[]>([] as Scenario[]);
  const [scene, setScene] = useState<Scene | undefined>(undefined);
  const [selectedScenario, setSelectedScenario] = useState<Scenario | undefined>(undefined);
  const [sentimentLogs, setSentimentLogs] = useState<SentimentLog[]>([] as SentimentLog[]);
  const [actionLogs, setActionLogs] = useState<ActionLog[]>([] as ActionLog[]);
  const [trialVideos, setTrialVideos] = useState<TrialVideo[]>([] as TrialVideo[]);
  const [selectedTrialVideo, setSelectedTrialVideo] = useState<TrialVideo | undefined>(undefined);
  const [selectedSentiment, setSelectedSentiment] = useState<SentimentLog | undefined>(undefined);
  const [actions, setActions] = useState<Action[]>([] as Action[]);
  const [filterSettings, setFilterSettings] = useState<FilterSettings>({types: []});
  const [currentVideoTime, setCurrentVideoTime] = useState<number>(0);
  const [newVideoTime, setNewVideoTime] = useState<number>(0);
  const [videoDuration, setVideoDuration] = useState<number>(0);
  const [lookNames, setLookNames] = useState<string[]>([]);

  useEffect(() => {
    if (trialId) {
      apiTrials.getTrial(trialId).then((response) => {
        setSelectedTrial(response);
      });

      apiSentimentLogs.getSentimentLogsForTrial(trialId).then((response) => {
        setSentimentLogs(response);
      });

      apiActionLogs.getActionLogsForTrial(trialId).then((response) => {
        setActionLogs(response);
      });

      apiActions.getActions().then((response) => {
        setActions(response);
      });

      apiTrialVideos.getVideosForTrial(trialId).then((response) => {
        setTrialVideos(response);
      });
    }
  }, [trialId]);

  useEffect(() => {
    if (selectedTrial && selectedTrial.id) {
      apiExperiments.getExperiment(selectedTrial.experimentId).then((response) => {
        setExperiment(response);
      });
    }
  }, [selectedTrial]);

  useEffect(() => {
    if (experiment && experiment.id) {
      apiScenarios.getScenariosForExperiment(experiment.id).then((response) => {
        setScenarios(response);
        setSelectedScenario(response[0]);
      });
    }
  }, [experiment]);

  useEffect(() => {
    selectVideo();
    if (selectedScenario && selectedScenario.id) {
      apiScenes.getScene(selectedScenario.sceneId).then((response) => {
        setScene(response);
      });

      setSelectedSentiment(undefined);
    }
  }, [selectedScenario]);

  useEffect(() => {
    const types = map(actions, (action) => action.id);
    const nonMovement = filter(types, (type) => type !== 'movement');

    const updatedFilters = cloneDeep(filterSettings);
    updatedFilters.types = nonMovement;

    setFilterSettings(updatedFilters);
  }, [actions]);

  useEffect(() => {
    const filteredLogs = FilterUtil.filterLogs(actionLogs, filterSettings);
    setActionLogs(filteredLogs);
  }, [filterSettings]);

  useEffect(() => {
    const lookStats = ReportUtils.groupLookCountsByName(ReportUtils.getEvents(actionLogs, ActionType.Look));
    const lookStatNames = map(lookStats, (look) => look.name);
    setLookNames(lookStatNames);
  }, [actionLogs]);

  const selectScenario = (scenarioToSelect: Scenario): void => {
    setSelectedScenario(scenarioToSelect);
  };

  const selectVideo = (): void => {
    const videoToSelect = find(trialVideos, (trialVideo) => trialVideo.scenarioId === selectedScenario?.id);
    setSelectedTrialVideo(videoToSelect);
  };

  const getActionLogsForScenario = (scenarioId: string): ActionLog[] => filter(
    actionLogs,
    (log) => log.scenarioId === scenarioId,
  );

  const getSentimentLogsForScenario = (scenarioId: string): SentimentLog[] => filter(
    sentimentLogs,
    (log) => log.scenarioId === scenarioId,
  );

  const returnToExperiments = (): void => {
    navigate(`/experiments/${experiment?.id}`);
  };

  const getHeatMapWrapperStyles = () => ({
    backgroundImage: `url("data:${scene?.imageMimeType};base64, ${scene?.imageStringBase64}")`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  });

  const selectSentiment = (sentiment?: SentimentLog): void => {
    if (sentiment && selectedSentiment?.id === sentiment.id) {
      setSelectedSentiment(undefined);
    } else {
      setSelectedSentiment(sentiment);
    }
  };

  const onTypeFilterChange = (types: string[]): void => {
    const updatedFilters = cloneDeep(filterSettings);
    updatedFilters.types = types;

    setFilterSettings(updatedFilters);
  };

  const getStartTime = (): Date => {
    let startTime: Date;
    if (selectedTrialVideo && selectedScenario) {
      startTime = new Date(selectedTrialVideo.startTime);
    } else {
      const scenarioLogs = getActionLogsForScenario(selectedScenario?.id || '');
      startTime = ReportUtils.getLogTimeExtent(scenarioLogs)[0];
    }

    return startTime;
  };

  const getLookAnalysis = (): ReactNode => {
    const scenarioLogs = getActionLogsForScenario(selectedScenario?.id || '');
    return (
      <LookAnalysis
        lookLogs={ReportUtils.getEvents(scenarioLogs, ActionType.Look)}
        lookNames={lookNames}
        startTime={getStartTime()}
        duration={videoDuration}
        sentimentLogs={getSentimentLogsForScenario(selectedScenario?.id || '')}
        selectedSentiment={selectedSentiment}
      />
    );
  };

  const getScenarioSummary = (scenario?: Scenario): ReactNode | undefined => {
    let scenarioSummary;

    if (scenario && selectedTrial) {
      scenarioSummary = (
        <ReportScenarioSummary
          key={scenario.id}
          startTime={getStartTime()}
          sentimentLogs={getSentimentLogsForScenario(scenario.id || '')}
          selectedSentiment={selectedSentiment}
        />
      );
    }

    return scenarioSummary;
  };

  const getExperimentDurationString = (): string => {
    let totalDuration = 0;
    forEach(scenarios, (scenario) => {
      totalDuration += getDuration(getActionLogsForScenario(scenario.id || ''));
    });

    return getDurationText(totalDuration);
  };

  const getScenarioSummaries = (): ReactNode[] => map(
    scenarios,
    (scenario, index) => getScenarioSummary(scenario),
  );

  const getScenarioLabel = (id: string): 'A' | 'B' => {
    const index = findIndex(scenarios, (scenario) => id === scenario.id);
    return index ? 'B' : 'A';
  };

  const getHeatMap = (): ReactNode => (
    <Box sx={[heatMapWrapper, getHeatMapWrapperStyles()]}>
      <HeatMap
        actionLogs={getActionLogsForScenario(selectedScenario?.id || '')}
      />
    </Box>
  );

  const updateVideoTime = (time: number): void => {
    setNewVideoTime(time);
  };

  const onVideoLoaded = (duration: number): void => {
    setVideoDuration(duration);
  };

  const getReportVideo = (): ReactNode | undefined => {
    let reportVideo;
    if (selectedTrialVideo && selectedScenario) {
      reportVideo = (
        <Box sx={reportVideoWrapper}>
          {/* <Box sx={reportScenarioSummaryRow}>
            <Typography variant="h4">{selectedScenario.sceneName}</Typography>
            <Typography variant="h4">Scenario: A</Typography>
            <Typography variant="h4">{getDuration(getActionLogsForScenario(selectedScenario.id || ''))}</Typography>
          </Box> */}
          <ReportVideo
            newVideoTime={newVideoTime}
            videoData={selectedTrialVideo}
            onVideoUpdate={(time) => setCurrentVideoTime(time)}
            onVideoLoaded={(duration) => onVideoLoaded(duration)}
          />
        </Box>
      );
    }

    return reportVideo;
  };

  const getSceneVisual = (): ReactNode | undefined => {
    let sceneVisual;
    if (scene && scene.type === SceneType.HeatMap) {
      sceneVisual = getHeatMap();
    } else if (scene?.type === SceneType.Video) {
      sceneVisual = getReportVideo();
    }

    return sceneVisual;
  };

  return (
    <Suspense>
      <Box sx={appWrapper}>
        <NavBar title="Report" />
        <Box component="main" sx={ReportContentWrapper}>
          <Box sx={reportPageDataColumn}>
            <ReportInfo
              trial={selectedTrial}
              experimentName={experiment?.name}
              duration={getExperimentDurationString()}
              onReturn={() => returnToExperiments()}
            />
            {getSceneVisual()}
            <Timeline
              actionLogs={getActionLogsForScenario(selectedScenario?.id || '')}
              sentimentLogs={getSentimentLogsForScenario(selectedScenario?.id || '')}
              selectSentiment={(sentiment) => selectSentiment(sentiment)}
              selectedSentiment={selectedSentiment}
              currentTime={currentVideoTime}
              sceneType={scene?.type}
              updateVideoTime={(time) => updateVideoTime(time)}
              trialVideo={selectedTrialVideo}
              videoDuration={videoDuration}
              lookNames={lookNames}
            />
            <ReportActionLogsTable
              testLabel={getScenarioLabel(selectedScenario?.id || '')}
              actionLogs={getActionLogsForScenario(selectedScenario?.id || '')}
              typeSettings={filterSettings.types}
              actions={actions}
              onTypesChange={(types) => onTypeFilterChange(types)}
            />
          </Box>
          <Box sx={reportPageAnalysisColumn}>
            <Box sx={reportLookAnalysisWrapper}>
              {getLookAnalysis()}
            </Box>
            {/* <Box sx={scenarioSummariesWrapper}>
              {getScenarioSummary(selectedScenario)}
            </Box> */}
          </Box>
        </Box>
      </Box>
    </Suspense>
  );
}

export default Secure(ReportPage);
