import {FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import {map} from 'lodash';
import React, {ReactNode} from 'react';
import {flexGrow} from '../../styles/styles';

type SelectFieldItem = {
  value: string;
  name: string
};

type SelectFieldProps = {
  selectedValue?: string;
  items: SelectFieldItem[];
  label: string;
  helperText?: string;
  onChange: (value: string) => any;
};

function SelectField(props: SelectFieldProps) {
  const getMenuItem = (item: SelectFieldItem): ReactNode => (
    <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
  );

  const getMenuItems = (): ReactNode[] => map(props.items, (item) => getMenuItem(item));

  const handleChange = (event: SelectChangeEvent) => {
    props.onChange(event.target.value);
  };

  return (
    <FormControl variant="filled" sx={flexGrow}>
      <InputLabel id="demo-simple-select-filled-label">{props.label}</InputLabel>
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={props.selectedValue}
        onChange={handleChange}
        error={!!((props.helperText && props.helperText.length > 0))}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {getMenuItems()}
      </Select>
      <FormHelperText
        variant="standard"
        error
        hidden={!((props.helperText && props.helperText.length > 0))}
      >
        {props.helperText}
      </FormHelperText>
    </FormControl>
  );
}

export default SelectField;
