import {reportContentWrapper, flex, reportPanel, panel, contentWrapper} from '../../styles/styles';

const ReportContentWrapper = {
  ...contentWrapper,
  flexDirection: 'row',
  gap: 1,
  padding: '1rem',
};

const reportSummaryRow = {
  display: 'flex',
  flex: '0 1',
  flexDirection: 'row',
  alignItems: 'stretch',
  gap: 1,
};

const reportScenarioRow = {
  ...panel,
  display: 'flex',
  flex: '1 0',
  flexDirection: 'row',
  alignItems: 'stretch',
  gap: 1,
  padding: '1rem',
};

const scenarioSummariesWrapper = {
  // ...panel,
  display: 'flex',
  flex: '0 1',
  flexDirection: 'column',
  alignItems: 'stretch',
};

const reportScenarioRowGraphs = {
  // ...panel,
  display: 'flex',
  flex: '1 0',
  flexDirection: 'column',
  maxWidth: '965px',
  alignItems: 'stretch',
  textAlign: 'center',
};

const reportVideoWrapper = {
  display: 'flex',
  flex: '0 1',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'space-between',
};

const reportScenarioRowTable = {
  ...panel,
  // display: 'flex',
  // flex: '0 1',
  // flexDirection: 'column',
};

const heatMapWrapper = {
  display: 'flex',
  flex: '1 0',
  alignItems: 'center',
  maxHeight: '500px',
  maxWidth: '965px',
};

const reportPageAnalysisColumn = {
  ...panel,
  display: 'flex',
  flex: '1 0',
  flexDirection: 'column',
  alignItems: 'stretch',
  padding: '1rem',
};

const reportLookAnalysisWrapper = {
  display: 'flex',
  flex: '1 0',
  flexDirection: 'column',
  alignItems: 'stretch',
};

const reportPageDataColumn = {
  ...panel,
  display: 'flex',
  flex: '1 0',
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'space-between',
  padding: '1rem',
};

const reportScenarioSummaryRow = {
  display: 'flex',
  flex: '0 1',
  flexDirection: 'row',
  padding: '.25rem',
  alignItems: 'stretch',
  justifyContent: 'space-between',
  '& button': {
    display: 'flex',
  },
  '& h2': {
    display: 'flex',
    textAlign: 'center',
    fontSize: '2rem',
    fontWeight: 400,
    color: '#436833',
    flex: '1 0 0',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    justifyContent: 'center',
  },
  '& h4': {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.25rem',
    fontWeight: 400,
    color: 'text.primary',
    flex: '1 0 0',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    justifyContent: 'center',

  },
};

export {
  ReportContentWrapper,
  reportSummaryRow,
  reportScenarioRow,
  reportScenarioRowGraphs,
  reportScenarioRowTable,
  heatMapWrapper,
  reportVideoWrapper,
  scenarioSummariesWrapper,
  reportPageAnalysisColumn,
  reportLookAnalysisWrapper,
  reportPageDataColumn,
  reportScenarioSummaryRow,
};
