import {Box, Button, TextField} from '@mui/material';
import React, {ChangeEvent, useState} from 'react';
import {Send} from '@mui/icons-material';
import {videoCodeFormHeader, videoCodeFormPanel} from './VideoCodeForm.styles';
import PanelHeader from '../PanelHeader/PanelHeader';

type VideoCodeFormProps = {
  onSubmit: (code: string) => any;
}

function VideoCodeForm(props: VideoCodeFormProps) {
  const [code, setCode] = useState<string>('');

  const submitCode = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.preventDefault();
    event.stopPropagation();

    props.onSubmit(code);
  };

  return (
    <Box sx={videoCodeFormPanel}>
      <PanelHeader
        sx={videoCodeFormHeader}
        title="Trial Code"
      />
      <Box>
        <TextField
          label="Code"
          value={code}
          id="code"
          name="Code"
          fullWidth
          variant="filled"
          required
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            setCode(value.replace(/\D/g, ''));
          }}
        />
        <Button
          variant="contained"
          startIcon={<Send />}
          onClick={(event) => submitCode(event)}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}

export default VideoCodeForm;
