import React from 'react';
import * as d3 from 'd3';
import {map} from 'lodash';
import {Box, Typography} from '@mui/material';
import ActionLog from '../../api/ActionLogs/ActionLog';
import FirstLookStat from '../LookAnalysis/FirstLookStat';
import * as ReportUtils from '../../Utils/ReportUtils';
import FirstLookRow from '../FirstLookRow/FirstLookRow';
import {firstLookHeader, firstLookRowsWrapper, firstLookWrapper} from './FirstLook.Style';

type FirstLooksProps = {
  lookLogs: ActionLog[];
  lookNames: string[];
  startTime: Date;
  duration: number;
};

function FirstLooks(props: FirstLooksProps) {
  const getLookRow = (lookStat: FirstLookStat, index: number) => {
    let color = d3.rgb(0, 200, 5).toString();
    if (props.lookNames.length > 0) {
      const lookColorScale = d3.scaleOrdinal(props.lookNames, ReportUtils.lookColorScheme);
      color = lookColorScale(lookStat.name);
    }

    return (
      <FirstLookRow
        key={index}
        firstLook={lookStat}
        startTime={props.startTime}
        duration={props.duration}
        color={color}
      />
    );
  };

  const getLookRows = () => {
    const firstLookStats = ReportUtils.groupByFirstLook(props.lookLogs);
    firstLookStats.sort((a, b) => d3.ascending(a.value, b.value));
    return map(firstLookStats, (stat, i) => getLookRow(stat, i));
  };

  return (
    <Box sx={firstLookWrapper}>
      <Box sx={firstLookHeader}>
        <Typography variant="h3">Time to First View</Typography>
      </Box>
      <Box sx={firstLookRowsWrapper}>
        {getLookRows()}
      </Box>
    </Box>
  );
}

export default FirstLooks;
