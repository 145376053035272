import React, {useRef} from 'react';
import {Box, Typography} from '@mui/material';
import Scenario from '../../api/Scenarios/Scenario';
import SentimentLog from '../../api/SentimentLogs/SentimentLog';
import * as ReportUtils from '../../Utils/ReportUtils';
import {
  reportScenarioSummaryPanel,
  reportScenarioSummaryRow,
} from './ReportScenarioSummary.Styles';
import {getDurationText} from '../../Utils/ReportUtils';
import SentimentSummary from '../SentimentSummary/SentimentSummary';
import TrialVideo from '../../api/TrialVideos/TrialVideo';
import ActionLog from '../../api/ActionLogs/ActionLog';
import Trial from '../../api/Trials/Trial';

type ReportScenarioSummaryProps = {
  startTime: Date;
  sentimentLogs: SentimentLog[];
  selectedSentiment?: SentimentLog;
}

function ReportScenarioSummary(props: ReportScenarioSummaryProps) {
  return (
    <SentimentSummary
      startTime={props.startTime}
      sentimentLogs={props.sentimentLogs}
      selectedSentiment={props.selectedSentiment}
    />
  );
}

export default ReportScenarioSummary;
