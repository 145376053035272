import {panel} from '../../styles/styles';

const firstLookWrapper = {
  ...panel,
  display: 'flex',
  flex: '1 0',
  flexDirection: 'column',
  alignItems: 'stretch',
  padding: '1rem',
};

const firstLookHeader = {
  display: 'flex',
  flex: '0 1',
  flexDirection: 'row',
  padding: '.25rem',
  justifyContent: 'space-between',
};

const firstLookRowsWrapper = {
  display: 'flex',
  flex: '1 0',
  flexDirection: 'column',
  alignItems: 'stretch',
  overflowX: 'auto',
};

export {
  firstLookWrapper,
  firstLookHeader,
  firstLookRowsWrapper,
};
